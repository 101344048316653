import React from 'react';
import {
  AppBar as MuiAppBar,
  Button,
  IconButton,
  Toolbar,
} from '@mui/material';
import {
  ArrowCircleLeftSharp,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import '../../css/appbar.css';
import logoCg from '../../images/logo-cg.png';
import { useWidth } from '../../../libs/hooks';
import { useNavigate } from 'react-router-dom';
import { UserAvatar } from "../../../libs/shared/components";

export const drawerWidth = 180;

const AppBarElement = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, small }) => ({
  height: `57px`,
  minHeight: `57px`,
  maxHeight: `57px`,
  zIndex: 1001,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: small === "true" ? 0 : drawerWidth,
    width: small === "true" ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar = ({
  handleDrawerOpen,
  open,
}) => {
  const width = useWidth();
  const navigate = useNavigate();

  return (
    <React.Fragment>
    <AppBarElement 
      position="fixed" 
      open={open} 
      small={width === "xs" ? "true" : "false"}
      >
      <Toolbar className='toolbar'>
      <div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            borderRadius: 2,
            border:"1px solid gainsboro",
            height: 32,
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Button
          onClick={()=>navigate(-1)}
          size="large"
          color="secondary"
          variant="outlined"
          sx={{
            position: "relative",
            left: open ? "-19px" : width === "xs" ? "-30px" : "-2px",
            marginRight:"-50px",
          }}
        >
          <ArrowCircleLeftSharp/>
        </Button>
        </div>
        <img src={logoCg} alt='CREATIVEGROUP' className='logo' />
        <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              right: "-10px",
            }}
          >
            <UserAvatar />
          </div>
      </Toolbar>
    </AppBarElement>
    </React.Fragment>

  )
}