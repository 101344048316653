// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import 'module-alias/register';
import { ReactQueryDevtools} from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack';

const client = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={ client }>
    <ReactQueryDevtools/>
    <SnackbarProvider maxSnack={3} hideIconVariant={false} dense >
    <App />
    </SnackbarProvider>
  </QueryClientProvider>
);



