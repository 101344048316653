import moment from "moment-timezone";

export const laZona = "America/Guayaquil";
export const dateNow = moment().tz(laZona).format().split("T")[0];

export const dateFormat = (date: Date | string) => {
  const fecha = new Date(date);

  const opciones = { day: "2-digit", month: "long", year: "numeric" } as any;
  const formato = new Intl.DateTimeFormat("es-ES", opciones).format(fecha);
  return formato;
};

export const dateFormatSlash = (date: Date | string) => {
  const fecha = new Date(date);

  const opciones = { day: "2-digit", month: "long", year: "numeric" } as any;
  const formato = new Intl.DateTimeFormat("es-ES", opciones).format(fecha);

  // Reemplazamos el "de" y ajustamos el formato
  const [day, , month, , year] = formato.split(" ");
  return `${day} / ${month.charAt(0).toUpperCase() + month.slice(1)} / ${year}`;
};
