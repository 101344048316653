import React from "react";
import {
    Navigate,
} from "react-router-dom";

import { GenericPage } from "../../../../../pages";
import {
    salesProformsRoutes
} from "./sales-proforms.route";
import { salesWareHouseRoutes } from "./sales-warehouse.route";
import { salesProforms2Routes } from "./sales-proforms2.route";
import { salesOpsRoutes } from "./sales-op.route";
import { salesVideossRoutes } from "./sales-video.route";

export const salesRoute = [
    // {
    //     path: 'proforms2',
    //     element: <GenericPage />,
    //     children: salesProforms2Routes
    // },
    {
        path: 'ops',
        element: <GenericPage />,
        children: salesOpsRoutes
    },
    {
        path: 'proforms',
        element: <GenericPage />,
        children: salesProforms2Routes
    },
    {
        path: 'videos',
        element: <GenericPage />,
        children: salesVideossRoutes
    },

    {
        path: 'warehouse',
        element: <GenericPage />,
        children: salesWareHouseRoutes
    },
    // {
    //     path: '',
    //     element: <Navigate replace to="/sales/proforms/list" />
    // },
];
