import React from "react";

// import { enqueueSnackbar } from "notistack";
import FondoDashboard from "../../images/bg-dashboard.jpg";
import { useRouterStore } from "../../../libs/stores";
import {
  UserCgComponent,
} from "./components";

const options = [
  { value: "hoy", label: "Hoy" },
  { value: "ayer", label: "Ayer" },
  { value: "ayerHoy", label: "Ayer & Hoy" },
  { value: "semana", label: "Esta Semana" },
  { value: "mes", label: "Este Mes" },
  { value: "3meses", label: "Últimos 3 Meses" },
];
export const Dashboard = () => {
  const typeDashboardCode = useRouterStore((state) => state.typeDashboardCode);

  // const [data, setData] = useState<any>({
  //   total: 0,
  //   equipments: [],
  // });
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     setLoading(false);
  //   } catch (err: any) {
  //     enqueueSnackbar(err.toString(), { variant: "error" });
  //     console.log(err);
  //   }
  // };

  // const handleLoading = useCallback(() => {
  //   setLoading(false);
  // }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundImage: `url(${FondoDashboard})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      {typeDashboardCode === "DASHBOARD_CLIENT" ? (
        <UserCgComponent options={options} />
      ) : null}
      {typeDashboardCode === "DASHBOARD_CLIENT_ADMINISTRATOR" ? (
        <UserCgComponent options={options} />
      ) : null}
      {typeDashboardCode === "DASHBOARD_ADMINISTRATOR" ? (
        <UserCgComponent options={options} />
      ) : null}
      {typeDashboardCode === "DASHBOARD_LUNCH" ? (
        <UserCgComponent options={options} />
      ) : null}
    </div>
  );
};
