import React from "react";
import {
  Navigate,
} from "react-router-dom";


const OpsAdd = React.lazy(() => import("../../../../../components/manager-video/components/add/page"));
const OpsList = React.lazy(() => import("../../../../../components/manager-video/components/list/page"));

export const salesVideossRoutes = [
    {
      path: 'list',
      element: <OpsList />,
    },
    {
      path: 'add',
      element: <OpsAdd />,
    },
    {
      path: 'edit',
      element: <Navigate to="/sales/videos/add" />
    },
    {
      path: 'edit/:id',
      element: <OpsAdd />
    },
    {
      path: '',
      element: <Navigate replace to="/sales/videos/list" />
    },
  ]