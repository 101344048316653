import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import {
  CircularProgress
} from '@mui/material';

// import 'semantic-ui-css/semantic.min.css';

import { router } from './Router'
import { creativeTheme } from './libs/theme/theme';
import { SnackbarProvider } from 'notistack';
import {
  LoadingFullPage,
} from "./pages";
import { useRouterStore } from './libs/stores';

export const App = () => {

  // const getModulesFromApi = useRouterStore((state)=>state.getModulesFromApi);

  // useEffect(()=>{
  //   getModulesFromApi();
  // },[])

  return (
    <SnackbarProvider maxSnack={3} hideIconVariant={false} dense >
      <ThemeProvider theme={creativeTheme}>
        <Suspense fallback={<LoadingFullPage/>}>
          <RouterProvider
            router={router}
            fallbackElement={<CircularProgress />}
          />
        </Suspense>
      </ThemeProvider>
    </SnackbarProvider>

  );
}


