import React from "react";
import {
    Navigate,
} from "react-router-dom";

const CredentialsListComponent = React.lazy(() => import("../../../../../components/credentials/components/list/page"));
const CredentialsAddComponent = React.lazy(() => import("../../../../../components/credentials/components/add/page"));


export const credentialsRoute = [
    {
        path: 'list',
        element: <CredentialsListComponent />,
    },
    {
        path: 'add',
        element: <CredentialsAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/options/credentials/add" />
    },
    {
        path: 'edit/:id',
        element: <CredentialsAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/credentials/list" />
    },
];