import React from "react";
import { GenericPage } from "../../../../../pages";
import {
  profilesRoute
} from "./options-profile.route";
import {
  usersRoute
} from "./options-user.route";
import { systemRoute } from "./options-system.route";
import { migrationsRoute } from "./options-migrations.route";
import { servicesRoute } from "./options-service.route";
import { credentialsRoute } from "./options-credentials.route";


export const optionsRoute = [
  {
    path: 'credentials',
    element: <GenericPage />,
    children:credentialsRoute,
  },

  {
    path: 'profiles',
    element: <GenericPage />,
    children:profilesRoute,
  },
  {
    path: 'users',
    element: <GenericPage />,
    children:usersRoute,
  },
  {
    path: 'migrations',
    element: <GenericPage />,
    children:migrationsRoute,
  },
  {
    path: 'system',
    element: <GenericPage />,
    children:systemRoute,
  },
  {
    path: 'service',
    element: <GenericPage />,
    children:servicesRoute,
  },
  
]