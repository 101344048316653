import React from "react";
import "../../css/card-welcome.css";
import { useRouterStore } from "../../../../../libs/stores";
import { dateFormat, dateFormatSlash } from "../../../../../libs/helpers";
import { API_KEY_WEATHER_CODE } from "../../../../../libs/constants";
import { useGeolocation } from "@uidotdev/usehooks";
import { getParameterByCode } from "../../../../../libs/apis";
import { message, Spin } from "antd";
import * as motion from "motion/react-client";

export const CardWelcomeComponent = () => {
  // const fullName = useRouterStore((state) => state.fullName);
  const nameSort = useRouterStore((state) => state.nameSort);
  const [temperature, setTemperature] = React.useState("");
  const [srcClima, setSrcClima] = React.useState("");
  const coordinates = useGeolocation({
    enableHighAccuracy: true, // Solicita una mayor precisión
    maximumAge: 30000, // Tiempo máximo en ms para considerar una ubicación "reciente"
    timeout: 27000, // Tiempo máximo en ms para esperar una respuesta
  });

  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    if (result.state === "denied") {
      // Mostrar mensaje al usuario indicando que debe habilitar la ubicación.
      message.error(
        "La geolocalización está deshabilitada. Por favor, habilítala!"
      );
    }
  });

  React.useEffect(() => {
    if (coordinates.latitude && coordinates.longitude) {
      getWeather(coordinates.latitude, coordinates.longitude);
    }
  }, [coordinates.latitude, coordinates.longitude]);

  async function getWeather(lat: any, lon: any) {
    try {
      const { data: dataParameter } = await getParameterByCode(
        API_KEY_WEATHER_CODE
      );
      if (!dataParameter) return;

      const url = `https://api.weatherapi.com/v1/current.json?key=${dataParameter.value}&q=${lat},${lon}&lang=es`;

      const response = await fetch(url);
      if (!response.ok) throw new Error("Error al obtener el clima");

      const data = await response.json();
      setTemperature(data.current.temp_c);
      setSrcClima(data.current.condition.icon);
    } catch (error: any) {
      message.error("Error:", error.message);
    }
  }

  return (
    <motion.div
      whileHover={{ scale: 1.09 }}
      whileTap={{ scale: 0.8 }}
      style={box}
    >
      <div
        className="card-component-welcome"
        // elevation={3}
      >
        <span className="title-welcome-one">Bienvenido(a),</span>
        <p className="title-welcome-name">{nameSort}</p>
        <br />
        <div className="container-clima">
          
          {coordinates.loading ? (
            <Spin size="small" >Obteniendo la ubicación...</Spin>
          ) : (
            <>
            <p className="title-welcome-name">{temperature} °F</p>
            <img
              src={srcClima}
              style={{ width: "50px", height: "50px" }}
              alt="CREATIVEGROUP EC"
            />
            </>
          )}
        </div>
        <span className="title-welcome-date">{dateFormatSlash(new Date())}</span>
        <span className="title-welcome-hour">{getHour()}</span>
      </div>
    </motion.div>
  );
};

const getHour = () => {
  const date = new Date();
  const formattedTime = date.toLocaleTimeString("es-ES", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit", // Puedes omitirlo si no necesitas segundos
    hour12: false, // Cambia a true si prefieres formato de 12 horas
  });
  return `${formattedTime}`;
};

const box = {
  width: "100%",
  height: "150px",
  backgroundColor: "none",
  borderRadius: 5,
};
