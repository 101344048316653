import { ModuleI, OptionI } from "../../interfaces";
import { getAllModulesByProfileId } from "../../apis";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type DashboardCodes =
  | "DASHBOARD_CLIENT"
  | "DASHBOARD_CLIENT_ADMINISTRATOR"
  | "DASHBOARD_ADMINISTRATOR"
  | "DASHBOARD_LUNCH"
  | null;

type State = {
  options: OptionI[];
  modules: ModuleI[];
  profileId: string;
  clientId: number | null;
  avatar: string;
  cloneCatalogue: number | null;
  cloneParameter: number | null;
  typeDashboardCode: DashboardCodes;
  firstName: string;
  section: string;
  previousModuleBase: string;
  isLoading: boolean;
  fullName: string;
  nameSort: string;
};

type Actions = {
  setProfileId: (profileId: string) => void;
  setTypeDashboardCode: (typeDashboardCode: DashboardCodes) => void;
  setClientId: (clientId: number) => void;
  setOptions: (options: OptionI[]) => void;
  setModules: (modules: ModuleI[]) => void;
  setAvatar: (avatar: string) => void;
  setFullName: (fullName: string) => void;
  reset: () => void;
  resetOptions: () => void;
  resetModules: () => void;
  getModulesFromApi: () => void;
  setCloneCatalogue: (catalogue: State["cloneCatalogue"]) => void;
  setCloneParameter: (catalogue: State["cloneParameter"]) => void;
  setFirstName: (firstName: string) => void;
  setSection: (section: State["section"]) => void;
  setPreviousModuleBase: (
    previousModuleBase: State["previousModuleBase"]
  ) => void;
  setLoading: (isLoading: State["isLoading"]) => void;
  setNameSort: (nameSort: State["nameSort"]) => void;
  validateModulesFromApi: () => Promise<any>;

};

const initialState: State = {
  options: [],
  modules: [],
  profileId: "",
  avatar: "",
  nameSort: "",
  cloneCatalogue: null,
  cloneParameter: null,
  clientId: null,
  typeDashboardCode:null,
  firstName:"",
  previousModuleBase: "",
  section: "",
  isLoading: false,
  fullName:"",
};

export const useRouterStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      fullName:"",
      nameSort:"",
      options: [],
      modules: [],
      profileId: "",
      avatar: "",
      cloneCatalogue: null,
      cloneParameter: null,
      clientId: null,
      firstName:"",
      typeDashboardCode:null,
      isLoading: false,
      previousModuleBase: "",
      section: "",
      reset: () => {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
        set(initialState);
      },
      setNameSort:(nameSort)=>set(()=>({nameSort})),
      setFullName:(fullName)=>set(()=>({fullName})),
      setFirstName:(firstName)=>set(()=>({firstName})),
      setTypeDashboardCode: (typeDashboardCode) => set((state) => ({ typeDashboardCode })),
      resetOptions: () => set((state) => ({ options: [] })),
      resetModules: () => set((state) => ({ modules: [] })),
      setPreviousModuleBase: (previousModuleBase) =>
        set((state) => ({ previousModuleBase })),
      setLoading: (loading) => set({ isLoading: loading }),
      setSection: (section) => set(() => ({ section })),
      getModulesFromApi: async () => {
        // const token = localStorage.getItem(process.env.REACT_APP_TOKEN!);

        // if (!token) {
        //   localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
        //   get().reset();
        //   return;
        // }
        // if (!get().profileId) {
        //   localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
        //   get().reset();
        //   return;
        // }

        // const { data: dataModules } = await getAllModulesByProfileId(
        //   get().profileId!
        // );
        // get().setModules(dataModules!);
      },
      validateModulesFromApi: async () => {
        set({ isLoading: true });
        try {
          const token = localStorage.getItem(process.env.REACT_APP_TOKEN!);

          if (!token || !get().profileId) {
            console.error("No se encontró un token o profileId");
            set({ isLoading: false });
            return;
          }

          const { data: dataModules } = await getAllModulesByProfileId(
            get().profileId
          );

          if (dataModules) {
            const opts = Array.from(
              new Map(
                dataModules
                  .flatMap((mod) => mod.options || [])
                  .map((option) => [option.id, option]) // Usar `id` como clave única
              ).values()
            );
            set({
              modules: dataModules,
              options: opts,
            });
          } else {
            set({ modules: [], options: [] });
          }
          // return Promise.resolve(dataModules);
          return dataModules;
        } catch (error) {
          set({ modules: [], options: [] });
        } finally {
          set({ isLoading: false }); // Finaliza la carga
        }
      },
      setOptions: (options) => set({ options }),
      setModules: (modules) => set((state) => ({ modules })),
      setProfileId: (profileId) => set((state) => ({ profileId })),
      setClientId: (clientId) => set((state) => ({ clientId })),
      setAvatar: (avatar) => set((state) => ({ avatar })),
      setCloneCatalogue: (cloneCatalogue) =>
        set((state) => ({ cloneCatalogue })),
      setCloneParameter: (cloneParameter) =>
        set((state) => ({ cloneParameter })),
    }),
    { name: process.env.REACT_APP_TOKEN_ROUTER! }
  )
);
