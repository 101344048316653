import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useWidth } from "../../../../../libs/hooks";
import {
  Badge,
  message,
  Select,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import { CardComponent } from "./card-component";
import { TableComponent } from "./table-component";
import {
  countLunchTicketsByStatus,
  countLunchTicketsTotals,
} from "../../../../../libs/apis";
import { TableLunch } from "./table-lunch";
import { CardWelcomeComponent } from "./card-welcome";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

export const UserCgComponent = ({ options }: { options: any[] }) => {
  const width = useWidth();
  const [valueTotals, setValueTotals] = useState("Hoy");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalValues, setTotalValues] = useState(0);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    try {
      handleGetTotals("hoy");
    } catch (err: any) {
      message.error(err.toString());
    }
  };
  const handleUpdateTotals = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueTotals(fill.label);
      handleGetTotals(fill.value);
    },
    [options]
  );

  const handleGetTotals = async (value: string) => {
    try {
      const { data: dataTotals } = await countLunchTicketsTotals(value);
      if (!dataTotals) return;
      setTotalQuantity(dataTotals.total);
      setTotalValues(dataTotals.sumValor);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={6} sm={3}>
          <CardWelcomeComponent />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Almuerzos Solicitados"
            quantity={totalQuantity}
            handleUpdate={handleUpdateTotals}
            options={options}
            color="tomato"
            value={valueTotals}
            total={totalValues}
          />
        </Grid>
        <Grid item xs={12}>
          <TableLunch />
        </Grid>
      </Grid>
    </Box>
  );
};
