import React from "react";
import { Badge, ConfigProvider, Select } from "antd";
import "../../css/card-css.css";
import * as motion from "motion/react-client";

interface CardComponentPropsI {
  width: string;
  title: string;
  quantity: number;
  handleUpdate: (value: string) => void;
  options:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  color: string;
  value: string;
  total?: number;
}

export const CardComponent = React.memo(
  ({
    width,
    quantity,
    title,
    handleUpdate,
    options,
    color,
    value,
    total,
  }: CardComponentPropsI) => {
    return (
      <motion.div
        whileHover={{ scale: 1.08 }}
        whileTap={{ scale: 0.8 }}
        style={box}
      >
        <div className="card-component">
          <Badge
            count={quantity}
            color={color}
            showZero
            style={{
              width: 50,
              height: 50,
              fontSize: 25,
              lineHeight: "50px",
              borderRadius: "50%",
              marginTop: "-10px",
            }}
          />
          {total ? (
            <span
              style={{
                fontWeight: "bold",
                fontSize: 14,
                position: "absolute",
                left: 5,
                bottom: 5,
              }}
            >
              ${total}
            </span>
          ) : null}
          <span className="title">{title}</span>
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  fontSize: 11,
                },
              },
            }}
          >
            <Select
              variant="filled"
              style={{
                width: width === "xs" ? "auto" : 100,
                position: "absolute",
                bottom: 5,
                right: 5,
              }}
              size="small"
              value={value}
              onChange={handleUpdate}
              options={options}
            />
          </ConfigProvider>
        </div>
      </motion.div>
    );
  }
);

const box = {
  width: "100%",
  height: "150px",
  backgroundColor: "none",
  borderRadius: 5,
};
