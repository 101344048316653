import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import {
  Key as KeyIcon,
  Person as PersonIcon,
  Check as CheckIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";

import { usersLoginPost } from "../../libs/apis";
import { useRouterStore } from "../../libs/stores";
import CenefaLogin from "../../images/logo.png";
import FondoLogin from "../images/fondo-min.jpg";
import "../css/login-new.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import VideoBackground from "../../images/video-cg-bg.mp4";
import { Input, Button} from "antd";

export const Login = () => {
  const navigate = useNavigate();
  const tokens = localStorage.getItem(process.env.REACT_APP_TOKEN!);
  const setModules = useRouterStore((state) => state.setModules);
  const setProfileId = useRouterStore((state) => state.setProfileId);
  const setFullName = useRouterStore((state) => state.setFullName);
  const setNameSort = useRouterStore((state) => state.setNameSort);
  const setClientId = useRouterStore((state) => state.setClientId);
  const setTypeDashboardCode = useRouterStore(
    (state) => state.setTypeDashboardCode
  );
  const setAvatar = useRouterStore((state) => state.setAvatar);
  const setFirstName = useRouterStore((state) => state.setFirstName);
  const [isProcessing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [typeInput, setTypeInput] = useState("password");
  const [continuo, setContinuo] = useState(false);
  const [videoLoaded, setVideoLoaded] = React.useState(false);
  
  React.useEffect(() => {
    if (continuo) {
      setContinuo(false);
      navigate("/dashboard", { replace: true });
    }
  }, [continuo]);

  const formik = useFormik({
    initialValues: {
      user: "",
      password: "",
    },
    validationSchema: Yup.object({
      user: Yup.string()
        .trim()
        .min(3, "Too Short!")
        .max(30, "Too Long!")
        .required("Required"),
      password: Yup.string()
        .trim()
        .min(5, "Too Short!")
        .max(40, "Too Long!")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setProcessing(true);
        const { data: dataUser } = await usersLoginPost(values);

        if (!dataUser) return;

        localStorage.setItem(
          process.env.REACT_APP_TOKEN!,
          JSON.stringify(dataUser)
        );
        enqueueSnackbar(`Bienvenido ${dataUser.user.fullName}`, {
          variant: "success",
        });
        setModules(dataUser.user.modules);
        setProfileId(dataUser.user.profileId);
        setClientId(dataUser.user.clientId!);
        setAvatar(dataUser.user.avatar!);
        setFirstName(dataUser.user.firstName!);
        setFullName(dataUser.user.fullName!);
        setNameSort(dataUser.user.nameSort!);
        setTypeDashboardCode(dataUser.user.typeDashboardCode!);
        setProcessing(false);
        formik.resetForm();
        setContinuo(true);
      } catch (err: any) {
        console.log(err);
        setProcessing(false);
        enqueueSnackbar(err ?? "Error, comuníquese con Sistemas", {
          variant: "error",
        });
      }
    },
  });

  if (tokens) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="login-container">
    {/* Imagen de fondo mientras se carga el video */}
    {!videoLoaded && (
      <img
        src={FondoLogin}
        alt="Fondo estático"
        className="video-background"
      />
    )}
    <video
      className="video-background"
      autoPlay
      loop
      muted
      playsInline
      onLoadedData={() => setVideoLoaded(true)}
    >
      <source src={VideoBackground} type="video/mp4" />
      Tu navegador no soporta videos HTML5.
    </video>
    <Grid container justifyContent="center" alignContent="center">
      <Grid item  lg={2.5} md={3} sm={6} xs={10}>
        <Paper
          elevation={3}
          sx={{
            minHeight: "500px",
            maxHeight: "500px",
            height: "500px",
            background: "rgb(14, 59, 95, 0.10)",
            border: "1px solid yellow",
            boxShadow: "9px 9px 5px -2px rgba(0, 0, 0, 0.81)",
            borderRadius: "10px",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignContent="flex-start"
              className="login-sub-root"
              spacing={1}
            >
              <Grid item xs={10} className="login-cenefa" style={{margin:"20px 0px 40px 0px"}}>
                <img
                  src={CenefaLogin}
                  width={150}
                  height={150}
                  alt="CREATIVEGROUP"
                />
              </Grid>
              <Grid item sm={9} xs={11} >
                <Input
                  min={3}
                  max={20}
                  name="user"
                  addonBefore={
                    <p style={{ color: "white", width: 80, padding:0, margin:0 }}>Usuario</p>
                  }
                  value={formik.values.user}
                  onChange={formik.handleChange}
                  size="large"
                  status={
                    formik.touched.user && formik.errors.user ? "error" : ""
                  }
                />
                {formik.touched.user && (
                  <p style={{ color: "red" }}>
                    {formik.touched.user && formik.errors.user}
                  </p>
                )}
              </Grid>
              <Grid item sm={9} xs={11} style={{marginTop:"10px"}}>
                <Input.Password
                  min={3}
                  max={30}
                  name="password"
                  size="large"
                  addonBefore={
                    <p style={{ color: "white", width: 80, padding:0, margin:0 }}>Contraseña</p>
                  }
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  status={
                    formik.touched.password && formik.errors.password
                      ? "error"
                      : ""
                  }
                />
                {formik.touched.password && (
                  <p style={{ color: "red" }}>
                    {formik.touched.password && formik.errors.password}
                  </p>
                )}
              </Grid>
              <Grid item sm={9} xs={11} style={{marginTop:"30px"}} >
              <Button
                  type="default"
                  htmlType="submit"
                  size="large"
                  style={{ width: "100%" }}
                  loading={isProcessing}
                  disabled={isProcessing}
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
};
