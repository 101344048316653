import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Chip,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getAllLunchs,
  getAllParameterChildrenbyParentCode,
  lunchTicketCancel,
  lunchTicketPrint,
  useLunchTickets,
} from "../../../../../libs/apis";
import {
  Refresh as RefreshIcon,
  FileDownload as FileDownloadIcon,
  Cancel as CancelIcon,
  EditOutlined,
} from "@mui/icons-material";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useSnackbar } from "notistack";
import {
  LunchI,
  LunchTicketI,
  LunchTicketUpdateCancelI,
  ParameterI,
} from "../../../../../libs/interfaces";
import {
  LUNCH_TICKET_IN_PROGRESS_CODE,
  LUNCH_TICKET_ISSUED_CODE,
  LUNCH_TICKET_SERVED_CODE,
  STATUSES_LUNCH_TICKET_CODE,
} from "../../../../../libs/constants";
import { useWidth } from "../../../../../libs/hooks";
import {
  ConfigProvider,
  DatePicker,
  Popover,
  Button as ButtonAntd,
  Tooltip as TooltipAntd,
  Popconfirm,
} from "antd";
import {
  ContainerOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { ExcelComponent, PdfElement } from "../../../../../libs/shared";
import "../../../../../libs/css/list.css";

const titleExport = "Tickets";
const routeEdit = "/lunchs/ticket-edit/";
const { RangePicker } = DatePicker;

export const TableLunch = () => {
  const width = useWidth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [sorting, setSorting] = useState([{ id: "id", desc: true }]);
  const [range, setRange] = useState([] as unknown as [string, string]);
  const [rangeVal, setRangeVal] = useState<any>([]);
  const [person, setPerson] = useState<number | null>(null);
  const [lunch, setLunch] = useState<number | null>(null);
  const [lunchString, setLunchString] = useState<string | null>(null);
  const [personString, setPersonString] = useState<string | null>(null);
  const [statusTicket, setStatusTicket] = useState<number | null>(null);
  const [statusString, setStatusString] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: width === "ab" ? 25 : width === "xl" ? 20 : 15,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const { lunchTicketsQuery } = useLunchTickets({
    sorting,
    pagination,
    globalFilter,
    person,
    lunch,
    statusTicket,
  });
  const { data, isLoading, refetch, fetchStatus } = lunchTicketsQuery;
  const [rowSelection, setRowSelection] = useState({});

  const [isDeleting, setDeleting] = useState(false);
  const [getExportData, setExportData] = useState([]);
  const [getExportHeader, setExportHeader] = useState([]);
  const [isContinuoToExport, setContinuoToExport] = useState(false);
  const [isReadyExport, setReadyExport] = useState(false);
  const [lunchs, setLunchs] = useState<LunchI[]>([]);
  const [statuses, setStatuses] = useState<ParameterI[]>([]);
  const [isPrinting, setPrinting] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (isContinuoToExport) {
      setContinuoToExport(false);
      setReadyExport(true);
    }
  }, [isContinuoToExport]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleGetData();
  }, []);

  const averagePerson = useMemo(
    () =>
      data?.data
        ?.dato!.filter((el) => el.ticketStatus !== "Anulado")
        .reduce((acc, curr) => acc + curr.pricePerson!, 0),
    [fetchStatus]
  );

  const columns = useMemo<MRT_ColumnDef<LunchTicketI>[]>(
    () => [
      {
        accessorKey: "detail",
        id: "detail",
        header: "Pedido",
        minSize: 150,
        size: 400,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <a
              className="b-proform"
              onClick={() => handleNavigate(row.original)}
            >{`Ticket ${renderedCellValue}`}</a>
          </Box>
        ),
      },
      {
        accessorKey: "ticketStatus",
        id: "ticketStatus",
        header: "Status",
        minSize: 100,
        size: 130,
        Cell: ({ renderedCellValue, row }) => (
         
            <Chip
              size="small"
              color={
                row.original.ticketStatusCode === LUNCH_TICKET_ISSUED_CODE
                  ? "default"
                  : row.original.ticketStatusCode ===
                    LUNCH_TICKET_IN_PROGRESS_CODE
                  ? "warning"
                  : row.original.ticketStatusCode === LUNCH_TICKET_SERVED_CODE
                  ? "success"
                  : "error"
              }
              label={row.original.ticketStatus}
            />
        ),
      },
      {
        accessorKey: "pricePerson",
        id: "pricePerson",
        header: "Valor",
        minSize: 100,
        size: 110,
        Footer: () => (
          <Stack style={{fontSize:"0.9rem", fontWeight:"bold"}} gap={0.2}>
            Total:
            <Box color="warning.main">
              {averagePerson?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        accessorKey: "date",
        id: "date",
        header: "Fecha",
        minSize: 100,
        size: 150,
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: "Creacion",
        minSize: 150,
        size: 150,
      },
    ],
    [averagePerson]
  );

  const handleGetData = async () => {
    try {
      const { data: dataLunch } = await getAllLunchs();
      const { data: dataStatus } = await getAllParameterChildrenbyParentCode(
        STATUSES_LUNCH_TICKET_CODE
      );
      if (!dataLunch) return;
      if (!dataStatus) return;

      setLunchs(dataLunch);
      setStatuses(dataStatus);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err!.toString(), { variant: "error" });
    }
  };

  const handleExportRows = (rows: any, columnsShow: any) => {
    const headersActive = columnsShow
      .slice(2)
      .filter((el: any) => el.getIsVisible())
      .map((el: any) => ({
        label: el.columnDef.header,
        id: el.columnDef.id,
        width: el.columnDef.size,
        textAlign: "left",
        show: true,
        fontSize: "10px",
      }));

    rows = rows.map((el: any) => el.original);

    setExportData(rows);
    setExportHeader(headersActive);
    setContinuoToExport(true);
  };

  const handleClickPdf = () => {
    setTimeout(() => {
      setReadyExport(false);
      setExportData([]);
      setExportHeader([]);
    }, 1500);
  };

  const handleResetExport = () => {
    setReadyExport(false);
    setExportData([]);
    setExportHeader([]);
  };

  const handleNavigate = useCallback((row: LunchTicketI) => {
    const routePath = routeEdit + row.id;
    navigate(routePath);
  }, []);

  const handleSelectLunch = (event: any, newValue: string | null) => {
    if (!newValue) {
      setLunch(null);
      setLunchString("");
      return;
    }

    setLunchString(newValue);
    const mlunch = lunchs.find((el) => el.name === newValue);

    if (!mlunch) return;

    setLunch(mlunch.id!);
  };

  const handleSelectStatus = (event: any, newValue: string | null) => {
    if (!newValue) {
      setStatusTicket(null);
      setStatusString("");
      return;
    }

    setStatusString(newValue);
    const status = statuses.find((el) => el.value === newValue);

    if (!status) return;

    setStatusTicket(status.id!);
  };

  const handlePrintTicket = async (row: LunchTicketI) => {
    try {
      setPrinting(true);
      const { data: dataTicket } = await lunchTicketPrint(row.id!);
      if (!dataTicket) return;

      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/pdf;base64,${dataTicket}`;
      downloadLink.download = `ticket-${row.id}.pdf`;
      downloadLink.click();
      enqueueSnackbar("Imprimiendo el Ticket...", { variant: "success" });
      setPrinting(false);
    } catch (err: any) {
      console.log(err);
      enqueueSnackbar(err.toString(), { variant: "error" });
      setPrinting(false);
    }
  };

  const handleCancel = async (row: LunchTicketI) => {
    try {
      const obj = {
        reason: "",
      } as LunchTicketUpdateCancelI;

      const { data: dataTicket } = await lunchTicketCancel(row.id!, obj);
      if (!dataTicket) return;

      enqueueSnackbar(dataTicket, { variant: "success" });
    } catch (err: any) {
      console.log(err);
      enqueueSnackbar(err.toString(), { variant: "error" });
      setPrinting(false);
    }
  };

  const FiltersComponent = React.memo(() => (
    <Fragment>
      <Grid item>
        <Autocomplete
          id="Lunch"
          options={lunchs.map((el) => el.name)}
          value={lunchString || ""}
          size="small"
          disabled={lunchs.length === 0 ? true : false}
          sx={{ width: 180 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Almuerzos"
              variant="outlined"
              color="primary"
            />
          )}
          onChange={handleSelectLunch}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="Status"
          options={statuses.map((el) => el.value)}
          value={statusString || ""}
          size="small"
          disabled={statuses.length === 0 ? true : false}
          sx={{ width: 150 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Status"
              variant="outlined"
              color="primary"
            />
          )}
          onChange={handleSelectStatus}
        />
      </Grid>
      <Grid item>
        <RangePicker
          value={rangeVal}
          onChange={(dates, date) => {
            setRangeVal(dates);
            setRange(date);
          }}
        />
      </Grid>
    </Fragment>
  ));

  return (
    <MaterialReactTable
      displayColumnDefOptions={{
        "mrt-row-actions": {
          muiTableHeadCellProps: {
            align: "center",
          },
          size: 100,
          maxSize: 100,
        },
      }}
      //OPTIONS
      enableStickyHeader
      enableStickyFooter
      enableDensityToggle
      enableColumnOrdering
      enableColumnResizing
      enableEditing
      enableGlobalFilter
      enableGrouping
      enableRowSelection
      enableColumnFilters={false}
      enablePagination
      manualFiltering
      manualPagination
      manualSorting
      muiTableBodyRowProps={({ row }) => ({
        onClick: row.getToggleSelectedHandler(),
        sx: { cursor: "pointer" },
      })}
      muiTableContainerProps={({ table }) => {
        return {
          sx: {
            minHeight: table.options.state.isFullScreen
              ? `calc(100vh - 100px)`
              : `calc(100vh - 370px)`,
            maxHeight: table.options.state.isFullScreen
              ? `calc(100vh - 100px)`
              : `calc(100vh - 370px)`,
            height: table.options.state.isFullScreen
              ? `calc(100vh - 100px)`
              : `calc(100vh - 370px)`,
          },
        };
      }}
      columns={columns}
      data={data?.data?.dato ?? []}
      initialState={{
        density: "compact",
        rowSelection,
        sorting,
        showColumnFilters: false,
      }}
      state={{
        isLoading,
        globalFilter,
        rowSelection,
        pagination,
        sorting,
        showColumnFilters: false,
      }}
      rowCount={data?.data?.total ?? 0}
      onRowSelectionChange={setRowSelection}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      muiPaginationProps={{
        rowsPerPageOptions: [5, 10, 20, 50, 100, 200, 500, 600, 800, 1000],
        showFirstButton: false,
        showLastButton: false,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Grid
          alignItems="center"
          container
          justifyContent="flex-start"
          spacing={0.5}
        >
          <Grid item>
            <Tooltip title="Refrescar">
              <span>
                <IconButton onClick={() => refetch()} color="warning">
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          {width === "xs" ? (
            <Grid item>
              <ConfigProvider
                theme={{
                  components: {
                    Popover: {
                      zIndexPopup: 1300,
                    },
                  },
                }}
              >
                <Popover
                  content={
                    <Grid container spacing={1}>
                      <FiltersComponent />
                    </Grid>
                  }
                  title="Filtros"
                  trigger="click"
                >
                  <ButtonAntd icon={<FilterOutlined />}>Filtros</ButtonAntd>
                </Popover>
              </ConfigProvider>
            </Grid>
          ) : (
            <FiltersComponent />
          )}
          <Grid item sx={{ display: "grid", placeContent: "center" }}>
            <Tooltip arrow title="Export Data">
              <span>
                <IconButton
                  disabled={
                    (!table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()) ||
                    isReadyExport
                  }
                  onClick={() =>
                    handleExportRows(
                      table.getSelectedRowModel().rows,
                      table.getAllColumns()
                    )
                  }
                  color="success"
                >
                  <FileDownloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item sx={{ display: "grid", placeContent: "center" }}>
            {isReadyExport ? (
              <PdfElement
                data={getExportData}
                headersColumn={getExportHeader}
                HandleClickPdf={handleClickPdf}
                isSmall={true}
              />
            ) : null}
          </Grid>
          <Grid item sx={{ display: "grid", placeContent: "center" }}>
            {isReadyExport ? (
              <ExcelComponent
                data={getExportData}
                titulo={"Tickets"}
                headersColumn={getExportHeader}
                isEcuafact={false}
              />
            ) : null}
          </Grid>
          <Grid item sx={{ display: "grid", placeContent: "center" }}>
            {isReadyExport ? (
              <Tooltip arrow title="Cancelar Exportación" placement="top">
                <IconButton
                  onClick={handleResetExport}
                  style={{ backgroundColor: "#ECF0F1 ", color: "red" }}
                  size="small"
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>
      )}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "0.4rem" }}>
          <TooltipAntd arrow={false} title="Ticket">
            <ButtonAntd
              type="dashed"
              size="small"
              disabled={isPrinting}
              loading={isPrinting}
              onClick={() => handlePrintTicket(row.original)}
              icon={<ContainerOutlined />}
            />
          </TooltipAntd>
          <TooltipAntd arrow={false} title="Editar">
            <ButtonAntd
              type="dashed"
              size="small"
              shape="circle"
              onClick={() => handleNavigate(row.original)}
              icon={<EditOutlined />}
            />
          </TooltipAntd>
          <TooltipAntd arrow={false} title="Anular">
            <Popconfirm
              title="Anular Ticket"
              description="Seguro desea Anular este Ticket?"
              onConfirm={() => handleCancel(row.original)}
              okText="Si"
              cancelText="No"
            >
              <ButtonAntd
                type="dashed"
                danger
                loading={isDeleting}
                disabled={isDeleting}
                size="small"
                shape="circle"
                icon={<ExclamationCircleOutlined />}
              />
            </Popconfirm>
          </TooltipAntd>
        </Box>
      )}
      localization={MRT_Localization_ES}
      // OPTIONS SELECTION ROW
      selectAllMode="all"
      positionToolbarAlertBanner="none"
    />
  );
};
