import React from "react";
import {
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { drawerWidth } from "../appbar/appbar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../css/drawer.css";
import { DynamicIcon } from "../../../libs/helpers";
import { ModuleI } from "../../../libs/interfaces";
import { Button as ButtonAntd } from "antd";
import { useRouterStore } from "../../../libs/stores";
import {
  COLOR_PRIMARY,
  NAVBAR_HEIGHT,
} from "../../../libs/constants";
import { useWidth } from "../../../libs/hooks";
import { HomeOutlined } from "@ant-design/icons";
import clsx from "clsx";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: 0,
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: "center",
  height: `57px`,
  minHeight: `57px`,
  maxHheight: `57px`,
  backgroundColor: COLOR_PRIMARY,
  color: "white",
  // border:"5px solid red",
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(78px + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(78px + 1px)`,
  },
});

const DrawerEl = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 1000,
  flexShrink: 0,
  whiteSpace: "nowrap",

  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface PropsDrawerI {
  open: boolean;
  handleClose: () => void;
}

interface PropsI {
  data: ModuleI;
  padding?: number;
  open: boolean;
}


export const Drawer = ({ open, handleClose }: PropsDrawerI) => {
  const width = useWidth();
  const theme = useTheme();
  const navigate = useNavigate();

  const modules = useRouterStore((state) => state.modules);

  const BodyDrawer = () => (
    <>
      <DrawerHeader>
        <ButtonAntd
          type="primary"
          style={{
            position: "relative",
            top: -1,
          }}
          onClick={handleClose}
          icon={
            theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )
          }
          size="small"
        />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Typography variant="h5" gutterBottom sx={{ m: 0 }}>
            CreativeGroup
          </Typography>
          <Typography variant="caption" gutterBottom sx={{ mt: -0.6 }}>
            No Procrastines
          </Typography> */}
          <ButtonAntd
            type="primary"
            danger
            onClick={handleNavigate}
            style={{
              position: "relative",
              top: -1,
            }}
            icon={<HomeOutlined />}
            size="small"
          />
        </Box>
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          p: 0,
          width: width === "xs" ? drawerWidth : "auto",
          overflowY: "auto",
          overflowX: "hidden",
          height: `calc(100vh - ${
            Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2
          }px)`,
          minHeight: `calc(100vh - ${
            Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2
          }px)`,
          maxHeight: `calc(100vh - ${
            Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2
          }px)`,
        }}
      >
        {modules.map((el) => (
          <DrawerItem key={`module_${el.id}`} data={el} open={open} />
        ))}
      </List>
    </>
  );

  const handleNavigate = () => {
    navigate("/dashboard");
    handleClose();
  };

  if (width === "xs") {
    return (
      <MuiDrawer open={open} onClose={handleClose}>
        <BodyDrawer />
      </MuiDrawer>
    );
  }
  return (
    <DrawerEl variant="permanent" open={open}>
      <BodyDrawer />
    </DrawerEl>
  );
};

export const DrawerItem = ({ data, open }: PropsI) => {
  const {
    id,
    name,
    nameSort,
    icon,
    iconLibrary,
    iconActive,
    iconActiveLibrary,
    children,
  } = data;
  const location = useLocation();


  const [openOption, setOpenOption] = React.useState(false);

  const onClickOption = () => setOpenOption((state) => !state);

    // Verifica si algún hijo está activo
    const isChildActive = children?.some((child) =>
      location.pathname.includes(child.to)
    );

  if (!children) {
    return <DrawerItemChildren data={data} padding={0} open={open} />;
  }

  if (children.length === 0) {
    return <DrawerItemChildren data={data} padding={0} open={open} />;
  }

  return (
    <>
      <ListItem
        key={`${id}_${name}_${nameSort}`}
        disablePadding
        divider
        className={clsx("listItem", {
          ["listItemActive"]: isChildActive,
          ["listItemOpen"]: openOption && !isChildActive,
        })}
      >
        <ListItemButton
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            height: 55,
          }}
          onClick={onClickOption}
        >
          <ListItemIcon>
            <DynamicIcon
              icon={openOption ? iconActive : icon}
              lib={openOption ? iconActiveLibrary : iconLibrary}
              color={openOption ? "#fff" : COLOR_PRIMARY}
              size={open ? 20 : 16}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            sx={{
              fontSize: open ? 12 : 10,
              fontWeight: open ? "bolder" : 600,
              position: "relative",
              left: open ? -30 : 0,
            }}
            primary={nameSort}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={openOption} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((el) => (
            <DrawerItemChildren
              key={`${el.id}_${el.code}`}
              data={el}
              padding={0.5}
              open={open}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const DrawerItemChildren = ({ data, padding, open }: PropsI) => {
  const {
    id,
    to,
    name,
    nameSort,
    icon,
    iconLibrary,
    iconActive,
    iconActiveLibrary,
    options,
    parentModuleId,
  } = data;

  // const setOptions = useRouterStore((state) => state.setOptions);

  return (
    <ListItem
      key={`${id}_${name}_${nameSort}`}
      disablePadding
      sx={{
        pl: padding,
        borderBottom: options ? "1px solid gainsboro" : "none",
        background: parentModuleId && options ? "#E5E7E9" : "white",
        height: parentModuleId && options ? 50 : 55,
      }}
      // onClick={() => {
      //   let options_ = options ?? [];
      //   options_ = options_.map((el) => ({ ...el, nodeRef: createRef() }));
      //   setOptions(options_);
      // }}
    >
      <NavLink to={to!} style={{ width: "100%" }}>
        {({ isActive, isPending }) => (
          <div className={isActive ? "navlinkA" : "navlink"}>
            <ListItemButton className="list-item-button" selected={isActive}>
              <ListItemIcon>
                <DynamicIcon
                  icon={isActive ? iconActive : icon}
                  lib={isActive ? iconActiveLibrary : iconLibrary}
                  color={isActive ? "#fff" : COLOR_PRIMARY}
                  size={open ? 20 : 16}
                />
              </ListItemIcon>
              <ListItemText
                // className="list-item-text"
                disableTypography
                sx={{
                  // fontSize: nameSort.length > 12 && !open ? 9 : 10,
                  fontSize: nameSort.length > 12 ? 10 : open ? 12 : 10,
                  fontWeight: open ? "bolder" : 600,
                  position: "relative",
                  left: open ? -30 : 0,
                }}
                primary={nameSort}
              />
            </ListItemButton>
          </div>
        )}
      </NavLink>
    </ListItem>
  );
};
