import { useQuery } from "@tanstack/react-query";
import { elementVideoApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsElementVideoI,
    ElementVideoI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getElementVideos = async ({
    pagination, 
    sorting, 
    globalFilter,
}:PropsElementVideoI):Promise<ServiceResponseI<DataI<ElementVideoI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await elementVideoApi.get<ServiceResponseI<DataI<ElementVideoI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
        
    }

    
}

export const getAllElementVideos = async ():Promise<ServiceResponseI<ElementVideoI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await elementVideoApi.get<ServiceResponseI<ElementVideoI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const elementVideoPost = async<T>(getForms: T): Promise<ServiceResponseI<string> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await elementVideoApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const elementVideoPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await elementVideoApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const elementVideoById = async (id: number): Promise<ServiceResponseI<ElementVideoI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await elementVideoApi.get<ServiceResponseI<ElementVideoI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const elementVideoDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await elementVideoApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useElementVideos = ({
    sorting,
    pagination,
    globalFilter,
}:PropsElementVideoI)=>{

    
    const elementVideosQuery = useQuery(
        ["elementVideos", { pagination, sorting, globalFilter }],
        ()=>getElementVideos({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        elementVideosQuery,
    };
}